<template>
	<div class="lc-operate-edit el-content">
		<a-tabs v-model:activeKey="active">
			<a-tab-pane :key="''+index" :tab="item.title" v-for="(item,index) in list">
				<div class="lco-form">
					<div class="name">
						<span v-if="!show.update_name">
							<span class="name-txt">{{ list[active].title }}</span>
							<i class="ri-edit-2-line" @click="show.update_name = true"></i>
						</span>
						<span v-else>
							<a-input  v-model:value="list[active].title" style="width: 300px;">
								<template #addonAfter>
									<div @click="show.update_name = false">完成</div>
								</template>
							</a-input>
						</span>
					</div>
					<kd-img-select :custom="true" :src="list[active].image" @change="(e)=>{list[active].image = e}">
						<img :src="list[active].image" alt="" style="width: 80px;height: 80px;">
					</kd-img-select>
					
					
					<!-- 开垦 -->
					<div v-if="list[active].type == 1">
						<div class="lco-form-item" v-for="(val,ind) in list[active].chil" :key="ind">
							<div class="title">
								<span>方案{{ ind+1 }}</span>
								<span  @click="addReckaim(ind)">删除方案</span>
							</div>
							<div class="items">
								<div class="items-li">
									<div>方案名称</div>
									<a-input v-model:value="val.title" placeholder="如：复垦方案一" style="width: 400px;"></a-input>
								</div>
								<div class="items-li">
									<div>方案背景图</div>
									<div style="width: 400px;text-align: left;">
										<kd-img-select
											:src="val.image" 
											@change="(e)=>{val.image = e}">
										</kd-img-select>
									</div>
								</div>
								<div class="items-li">
									<div>有作物计价</div>
									<a-input v-model:value="val.price">
										<template #addonAfter>
											<a-select v-model:value="val.company" style="width: 100px">
												<a-select-option value="1">元/m²</a-select-option>
												<a-select-option value="3">元/次</a-select-option>
											</a-select>
										</template>
									</a-input>
								</div>
								<div class="items-li">
									<div>空地计价</div>
									<a-input v-model:value="val.two_price">
										<template #addonAfter>
											<a-select v-model:value="val.two_company" style="width: 100px">
												<a-select-option value="1">元/m²</a-select-option>
												<a-select-option value="3">元/次</a-select-option>
											</a-select>
										</template>
									</a-input>
								</div>
							</div>
						</div>
						<div class="lco-form-add" @click="addReckaim(-1)">
							<i class="ri-add-line"></i>
						</div>
					</div>
				
					<!-- 浇水 -->
					<div v-if="list[active].type == 3">
						<div class="lcp-input">
							<div>提醒周期</div>
							<a-input v-model:value="list[active].cycle" placeholder="输入时间" style="width: 400px;" addon-after="天"></a-input>
						</div>
						<div class="lcp-input">
							<div>提醒内容</div>
							<a-input v-model:value="list[active].msg" placeholder="如:需浇水" style="width: 400px;"></a-input>
						</div>
						
						<div class="lco-form-item" v-for="(val,ind) in list[active].chil" :key="ind">
							<div class="title">
								<span>方案{{ ind+1 }}</span>
								<span  @click="addReckaim(ind)">删除方案</span>
							</div>
							<div class="items">
								<div class="items-li">
									<div>操作名称</div>
									<a-input v-model:value="val.title" placeholder="如：自动浇水30秒" style="width: 400px;"></a-input>
								</div>
								<div class="items-li">
									<div>浇水时长</div>
									<a-input v-model:value="val.times" placeholder="请输入" style="width: 400px;" addon-after="秒"></a-input>
								</div>
								<div class="items-li">
									<div>费用</div>
									<a-input v-model:value="val.price" placeholder="请输入" style="width: 400px;" addon-after="元/秒"></a-input>
								</div>
							</div>
						</div>
						<div class="lco-form-add" @click="addReckaim(-1)">
							<i class="ri-add-line"></i>
						</div>
					</div>
					
					<!-- 除草 -->
					<div v-if="list[active].type == 4">
						<div class="lcp-input">
							<div>提醒周期</div>
							<a-input v-model:value="list[active].cycle" placeholder="输入时间" style="width: 400px;" addon-after="天"></a-input>
						</div>
						<div class="lcp-input">
							<div>提醒内容</div>
							<a-input v-model:value="list[active].msg" placeholder="如:需除草" style="width: 400px;"></a-input>
						</div>
						<div class="lco-form-item" v-for="(val,ind) in list[active].chil" :key="ind">
							<div class="title">
								<span>方案{{ ind+1 }}</span>
								<span  @click="addReckaim(ind)">删除方案</span>
							</div>
							<div class="items">
								<div class="items-li">
									<div>方案名称</div>
									<a-input v-model:value="val.title" placeholder="如：人工除草" style="width: 400px;"></a-input>
								</div>
								<div class="items-li">
									<div>方案图例</div>
									<div style="width: 400px;text-align: left;">
										<kd-img-select
											:src="val.image" 
											@change="(e)=>{val.image = e}">
										</kd-img-select>
									</div>
								</div>
								<div class="items-li">
									<div>计价方式</div>
									<a-input v-model:value="val.price">
										<template #addonAfter>
											<a-select v-model:value="val.company" style="width: 100px">
												<a-select-option value="1">元/m²</a-select-option>
												<a-select-option value="3">元/次</a-select-option>
											</a-select>
										</template>
									</a-input>
								</div>
							</div>
						</div>
						<div class="lco-form-add" @click="addReckaim(-1)">
							<i class="ri-add-line"></i>
						</div>
					</div>
					
					<!-- 施肥 -->
					<div v-if="list[active].type == 5">
						<div class="lcp-input">
							<div>提醒周期</div>
							<a-input v-model:value="list[active].cycle" placeholder="输入时间" style="width: 400px;" addon-after="天"></a-input>
						</div>
						<div class="lcp-input">
							<div>提醒内容</div>
							<a-input v-model:value="list[active].msg" placeholder="如:需施肥" style="width: 400px;"></a-input>
						</div>
						
						<div class="lco-form-item" v-for="(val,ind) in list[active].chil" :key="ind">
							<div class="title">
								<span>方案{{ ind+1 }}</span>
								<span  @click="addReckaim(ind)">删除方案</span>
							</div>
							<div class="items">
								<div class="items-li">
									<div>方案名称</div>
									<a-input v-model:value="val.title" placeholder="如：化肥" style="width: 400px;"></a-input>
								</div>
								<div class="items-li">
									<div>方案图例</div>
									<div style="width: 400px;text-align: left;">
										<kd-img-select
											:src="val.image" 
											@change="(e)=>{val.image = e}">
										</kd-img-select>
									</div>
								</div>
								<div class="items-li">
									<div>计价方式</div>
									<a-input v-model:value="val.price">
										<template #addonAfter>
											<a-select v-model:value="val.company" style="width: 100px">
												<a-select-option value="1">元/m²</a-select-option>
												<a-select-option value="3">元/次</a-select-option>
											</a-select>
										</template>
									</a-input>
								</div>
							</div>
						</div>
						<div class="lco-form-add" @click="addReckaim(-1)">
							<i class="ri-add-line"></i>
						</div>
					</div>
					<!-- 除虫 -->
					<div v-if="list[active].type == 6">
						<div class="lcp-input">
							<div>提醒周期</div>
							<a-input v-model:value="list[active].cycle" placeholder="输入时间" style="width: 400px;" addon-after="天"></a-input>
						</div>
						<div class="lcp-input">
							<div>提醒内容</div>
							<a-input v-model:value="list[active].msg" placeholder="如:需除虫" style="width: 400px;"></a-input>
						</div>
						
						<div class="lco-form-item" v-for="(val,ind) in list[active].chil" :key="ind">
							<div class="title">
								<span>方案{{ ind+1 }}</span>
								<span  @click="addReckaim(ind)">删除方案</span>
							</div>
							<div class="items">
								<div class="items-li">
									<div>方案名称</div>
									<a-input v-model:value="val.title" placeholder="如：人工除虫" style="width: 400px;"></a-input>
								</div>
								<div class="items-li">
									<div>方案图例</div>
									<div style="width: 400px;text-align: left;">
										<kd-img-select
											:src="val.image" 
											@change="(e)=>{val.image = e}">
										</kd-img-select>
									</div>
								</div>
								<div class="items-li">
									<div>计价方式</div>
									<a-input v-model:value="val.price">
										<template #addonAfter>
											<a-select v-model:value="val.company" style="width: 100px">
												<a-select-option value="1">元/m²</a-select-option>
												<a-select-option value="3">元/次</a-select-option>
											</a-select>
										</template>
									</a-input>
								</div>
							</div>
						</div>
						<div class="lco-form-add" @click="addReckaim(-1)">
							<i class="ri-add-line"></i>
						</div>
					</div>
					
					<!-- 装饰 -->
					<div v-if="list[active].type == 8">
						<div class="lco-form-item" v-for="(val,ind) in list[active].chil" :key="ind">
							<div class="title">
								<span>方案{{ ind+1 }}</span>
								<span  @click="addReckaim(ind)">删除方案</span>
							</div>
							<div class="items">
								<div class="items-li">
									<div>方案名称</div>
									<a-input v-model:value="val.title" placeholder="如：复垦方案一" style="width: 400px;"></a-input>
								</div>
								<div class="items-li">
									<div>方案图例</div>
									<div style="width: 400px;text-align: left;">
										<kd-img-select
											:src="val.image" 
											@change="(e)=>{val.image = e}">
										</kd-img-select>
									</div>
								</div>
								<div class="items-li">
									<div>计价方式</div>
									<a-input v-model:value="val.price">
										<template #addonAfter>
											<a-select v-model:value="val.company" style="width: 100px">
												<a-select-option value="1">元/m²</a-select-option>
												<a-select-option value="3">元/次</a-select-option>
											</a-select>
										</template>
									</a-input>
								</div>
							</div>
						</div>
						<div class="lco-form-add" @click="addReckaim(-1)">
							<i class="ri-add-line"></i>
						</div>
					</div>
					
					<div v-if="list[active].type == 7">
						<div class="lcp-input">
							<div>采摘提醒</div>
							<a-input v-model:value="list[active].msg" placeholder="如:需除虫" style="width: 400px;"></a-input>
						</div>
						<div  v-for="(val,ind) in list[active].chil" :key="ind">
							<div class="lcp-input">
								<div>采摘单价</div>
								<a-input v-model:value="val.price" style="width: 400px;" addon-after="元/kg"></a-input>
							</div>
							<div class="lcp-input">
								<div>配送单价</div>
								<a-input v-model:value="val.two_price" style="width: 400px;" addon-after="元/kg"></a-input>
							</div>
						</div>
					</div>
					
				</div>
			</a-tab-pane>
		</a-tabs>
		<a-space style="margin-left: 60px;margin-top: 24px;">
			<a-button type="primary" @click="saveData">保存</a-button>
		</a-space>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import landCustom from '@/api/addons/landCustom.js'
export default{
	setup(){
		const _d = reactive({
			active:'0',
			show:{
				update_name:false,
			},
			list:[],
		})
		getworkNaviSetList()
		function getworkNaviSetList(){
			landCustom.getCustomWorkNav(res=>_d.list= res)
		}

		function addReckaim(e){
			let type = _d.list[_d.active].type
			if( e == -1 ){
				switch (type){
					case 1:	//开垦
						_d.list[_d.active].chil.push({
							title:"",
							image:"",
							price:"",
							company:"1",
							two_price:"",
							two_company:"1",
							special_type:_d.list[_d.active].type
						})
						break;
					case 3: //浇水
						_d.list[_d.active].chil.push({
							title:"",
							times:"",
							price:"",
							company:"6",
							special_type:_d.list[_d.active].type
						})
						break;
					case 4: //除草
						_d.list[_d.active].chil.push({
							title:"",
							image:"",
							price:"",
							company:"1",
							special_type:_d.list[_d.active].type
						})
						break;
					case 5: //施肥
						_d.list[_d.active].chil.push({
							title:"",
							image:"",
							price:"",
							company:"1",
							special_type:_d.list[_d.active].type
						})
						break;
					case 6: //除虫
						_d.list[_d.active].chil.push({
							title:"",
							image:"",
							price:"",
							company:"1",
							special_type:_d.list[_d.active].type
						})
						break;
					case 8:	//装饰
						_d.list[_d.active].chil.push({
							title:"",
							image:"",
							price:"",
							company:"1",
							special_type:_d.list[_d.active].type
						})
						break;
					default:
						break;
				}
				return
			}
			let data = _d.list[_d.active].chil[e]
			if( data.id ){
				landCustom.deleteWorkDetail(data.id,()=>{
					_d.list[_d.active].chil.splice(e,1)
				})
				return
			}
			_d.list[_d.active].chil.splice(e,1)
		}
		const saveData =()=>landCustom.addOrEditWorkDetail(_d.list[_d.active].chil,()=>getworkNaviSetList())
		const setworkNavi =()=> landCustom.addOrEditWorkNav(_d.list)
		return{
			...toRefs(_d),
			getworkNaviSetList,
			saveData,
			setworkNavi,
			addReckaim
		}
	}
}
</script>

<style lang="scss">
	.lco-form{
		padding-left: 24px;
		.name{
			margin-bottom: 12px;
			&-txt{
				font-weight: bold;
				font-size: 18px;
			}
			i{
				cursor: pointer;
			}
		}
		.lcp-input{
			display: flex;
			align-items: center;
			margin-top: 12px;
			>div{
				width: 120px;
				font-size: 13px;
				color: #666;
			}
		}
		&-item{
			width: 600px;
			margin-top: 24px;
			
			.title{
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				
				span:last-child{
					color: red;
					font-size: 12px;
					cursor: pointer;
				}
			}
			.items{
				width: 100;
				border: 1px solid #f4f4f4;
				padding: 24px;
				margin-top: 8px;
				&-li{
					display: flex;
					font-size: 12px;
					margin-bottom: 12px;
					
					>div{
						line-height: 32px;
						width: 80px;
						text-align: right;
						margin-right: 24px;
					}
				}
			}
		}
		&-add{
			width: 600px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			border: 1px dashed #dedede;
			margin-top: 12px;
			cursor: pointer;
		}
		&-btn{
			margin-top: 24px;
		}
	}
	.lc-btn{
		margin-left: 24px;
		margin-top: 24px;
	}
</style>
